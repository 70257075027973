
import { EWALLET_PROVIDER } from 'constants.js'
import { 
  formStructure as formStructure_NIUMEU, 
  stakeholdersFields as stakeholdersFields_NIUMEU, 
  documentDetailsCorporate as documentDetailsCorporate_NIUMEU, 
  documentDetailsPerson as documentDetailsPerson_NIUMEU
} from './nium/schemas/formSchemaEU'
import { 
  formStructure as formStructure_NIUMUK, 
  stakeholdersFields as stakeholdersFields_NIUMUK, 
  documentDetailsCorporate as documentDetailsCorporate_NIUMUK, 
  documentDetailsPerson as documentDetailsPerson_NIUMUK,
} from './nium/schemas/formSchemaUK'
import { documentDetailsCorporateStakeholders } from './nium/schemas/common'
import { allDocumentTypesUk, allDocumentTypesEu } from './nium/values'

export const FormStructureProviderMap = {
  [EWALLET_PROVIDER.NIUM_UK]: {
    formStructure: formStructure_NIUMUK,
    stakeholdersFields: stakeholdersFields_NIUMUK,
    documentDetailsCorporate: documentDetailsCorporate_NIUMUK,
    documentDetailsPerson: documentDetailsPerson_NIUMUK(),
    documentDetailsCorporateStakeholders,
    allDocumentTypes: allDocumentTypesUk,
  },
  [EWALLET_PROVIDER.NIUM_EU]: {
    formStructure: formStructure_NIUMEU,
    stakeholdersFields: stakeholdersFields_NIUMEU,
    documentDetailsCorporate: documentDetailsCorporate_NIUMEU,
    documentDetailsPerson: documentDetailsPerson_NIUMEU,
    documentDetailsCorporateStakeholders,
    allDocumentTypes: allDocumentTypesEu,
  }
}
