import { person, contact, documentDetailsCorporateStakeholders } from './common'
import { TYPE } from '../../formTypes'
import {
  positionUk,
  industrySector,
  totalEmployees,
  annualTurnoverUk,
  businessTypesUk,
  corporateDocumentTypeUk,
  identityDocTypesUk,
  addressDocTypesUk,
  authorityDocTypesUk,
  personDocumentTypeUk,
  intendedUseOfAccount,
  restrictedCountries,
  transactionCountriesUk,
} from '../values'
import { NIUM_STAKEHOLDER_TYPE } from 'constants.js'
import { getKeyByValue } from 'utils/functions'

export const address = {
  addressLine1: {
    label: "Address Line 1",
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  addressLine2: {
    label: "Address Line 2",
    type: TYPE.STRING,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  city: {
    label: "City",
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  country: {
    label: "Country",
    type: TYPE.COUNTRY,
    required: true
  },
  postcode: {
    label: "Postcode",
    type: TYPE.STRING,
    info: "Expected format SE1 5BY, there should be space between the two parts of the postcode",
    required: true,
    customErrorMessageTypes: [
      'string.pattern.base',
    ],
  },
  state: {
    label: "State",
    type: TYPE.STRING,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
}

export const documentDetailsCorporate = {
  documentType: {
    label: 'Document Type',
    type: TYPE.SELECT,
    options: corporateDocumentTypeUk,
    required: true,
  },
  "document.0.document": {
    label: "Document",
    type: TYPE.FILE,
    info: "File size limit: 1.5MB | Accepted formats: PDF, JPG, PNG",
    required: true,
  },
  documentNumber: {
    label: "Document Number",
    type: TYPE.STRING,
    required: true,
    info: 'e.g. incorporation number or passport, etc...'
  },
  documentIssuanceCountry: {
    label: "Document Issuance Country",
    type: TYPE.COUNTRY,
    required: true,
  },
}

export const documentDetailsPerson = (docType) => {
  let typeOptions = personDocumentTypeUk
  if(docType === 'IDENTITY') {
    typeOptions = identityDocTypesUk
  }
  if(docType === 'ADDRESS') {
    typeOptions = addressDocTypesUk
  }
  if(docType === 'AUTHORITY') {
    typeOptions = authorityDocTypesUk
  }
  return {
    documentType: {
      label: 'Document Type',
      type: TYPE.SELECT,
      options: typeOptions,
      required: true,
      ...(docType === 'IDENTITY' && { defaultValue: getKeyByValue(identityDocTypesUk, identityDocTypesUk.DRIVER_LICENCE) }),
      ...(docType === 'AUTHORITY' && { defaultValue: getKeyByValue(authorityDocTypesUk, authorityDocTypesUk.POWER_OF_ATTORNEY) }),
      ...(docType === 'IDENTITY' && { info: 'Change you ID type in the dropdown if needed e.g. Passport' }),
    },
    "document.0.document": {
      label: "Document",
      type: TYPE.FILE,
      info: "File size limit: 1.5MB | Accepted formats: PDF, JPG, PNG",
      infoAdditional: "For passport, select the same file again",
      required: true,
      ...(docType === 'IDENTITY' && { fileCount: 2, labels: ['Front', 'Back'] }),
    },
    documentNumber: {
      label: "Document Number",
      type: TYPE.STRING,
      required: true,
      info: 'e.g. incorporation number or passport, etc...'
    },
    documentIssuanceCountry: {
      label: "Document Issuance Country",
      type: TYPE.COUNTRY,
      required: true
    },
    documentExpiryDate: {
      label: "Document Expiry Date",
      type: TYPE.DATE,
      future: true,
      info: 'Please enter fictitious dates if not applicable',
    },
    documentIssuedDate: {
      label: "Document Issued Date",
      type: TYPE.DATE,
      past: true,
      info: 'Please enter fictitious dates if not applicable',
    },
    documentIssuingAuthority: {
      label: "Document Issuing Authority",
      type: TYPE.STRING,
      required: true,
      info: 'e.g. DVLA , HMPO'
    },
  }
}

export const stakeholdersFields = {
  stakeholderType: {
    type: TYPE.RADIO,
    options: {
      [NIUM_STAKEHOLDER_TYPE.INDIVIDUAL]: 'Individual',
      [NIUM_STAKEHOLDER_TYPE.CORPORATE]: 'Corporate (If owned by another company)'
    },
    required: true
  },
  businessPartner: {
    title: "Stakeholder Details",
    type: TYPE.GROUP,
    renderIf: {
      'stakeholders.index.stakeholderType': NIUM_STAKEHOLDER_TYPE.CORPORATE,
    },
    fields: {
      businessName: {
        label: "Business Name",
        type: TYPE.STRING,
        required: true
      },
      businessRegistrationNumber: {
        label: "Business Registration Number",
        type: TYPE.STRING,
        required: true
      },
      businessEntityType: {
        label: "Stakeholder Type",
        type: TYPE.SELECT,
        options: positionUk,
        required: true
      },
      sharePercentage: {
        label: "Share Percentage",
        type: TYPE.STRING,
        decimalDigits: 2,
        info: 'Enter 0 if no shares held. Do not add the % sign.'
      },
      "legalDetails.registeredCountry": {
        label: "Registered Country",
        type: TYPE.COUNTRY,
        required: true
      },
      documentDetails: {
        title: "Document Details",
        type: TYPE.GROUP,
        multiple: true,
        fields: [documentDetailsCorporateStakeholders]
      },
    }
  },
  stakeholderDetails: {
    title: "Stakeholder Details",
    type: TYPE.GROUP,
    renderIf: {
      'stakeholders.index.stakeholderType': NIUM_STAKEHOLDER_TYPE.INDIVIDUAL
    },
    fields: {
      ...person,
      birthCountry: {
        label: "Birth Country",
        type: TYPE.COUNTRY,
        required: true
      },
      address: {
        title: "Address Details",
        type: TYPE.GROUP,
        fields: address
      },
      "professionalDetails.0": {
        title: "Professional Details",
        type: TYPE.GROUP,
        fields: {
          position: {
            label: "Position",
            type: TYPE.SELECT,
            options: positionUk,
          },
          sharePercentage: {
            label: "Share Percentage",
            type: TYPE.STRING,
            decimalDigits: 2,
            info: 'Enter 0 if no shares held. Do not add the % sign.'
          },
        }
      },
      documentDetails: {
        title: "Document Details (ID documents must be a colour copy)",
        type: TYPE.GROUP,
        multiple: true,
        fields: [{
          ...documentDetailsPerson('IDENTITY'),
          documentIssuedDate: {
            label: "Document Issued Date",
            info: 'Please enter fictitious dates if not applicable',
            type: TYPE.DATE,
            past: true,
            required: true
          },
        },
        {
          ...documentDetailsPerson('ADDRESS'),
          documentIssuedDate: {
            label: "Document Issued Date",
            info: 'Please enter fictitious dates if not applicable',
            type: TYPE.DATE,
            past: true,
            required: true
          },
        }]
      },
    },
  },
}

export const formStructure = {
  region: {
    title: "Region",
    type: TYPE.GROUP,
    fields: {
      region: {
        label: "Region",
        type: TYPE.SELECT,
        options: { UK: 'UK' },
        required: true,
      }
    }
  },
  businessDetails: {
    title: "Business Details",
    type: TYPE.GROUP,
    fields: {
      businessName: {
        label: "Business Name",
        type: TYPE.STRING,
        required: true
      },
      businessRegistrationNumber: {
        label: "Business Registration Number",
        type: TYPE.STRING,
        required: true,
        info: 'If you do not have a business registration number, please enter your Unique Tax Reference (UTR) or VAT number'
      },
      tradeName: {
        label: "Trade Name",
        type: TYPE.STRING,
        required: true
      },
      website: {
        label: "Website",
        type: TYPE.STRING
      },
      businessType: {
        label: "Business Type",
        type: TYPE.SELECT,
        options: businessTypesUk,
        required: true
      },
      documentDetails: {
        title: "Document Details",
        info: 'Please upload a copy of your certificate of incorporation (mandatory). For more information on required documents, please click <a href="https://help.travelledger.org/tl-pay-account/account-registration-process/uk-application-kyb-kyc" target="_blank">here</a> to consult our Wiki page.',
        type: TYPE.GROUP,
        multiple: true,
        fields: [documentDetailsCorporate]
      },
      legalDetails: {
        title: "Legal Registration Details",
        type: TYPE.GROUP,
        fields: {
          registeredDate: {
            label: "Registration Date",
            type: TYPE.DATE,
            info: "The date the business was originally registered",
            required: true,
            past: true,
          },
          registeredCountry: {
            label: "Registered Country",
            type: TYPE.COUNTRY,
            required: true
          },
        }
      },
      addresses: {
        title: "Addresses",
        type: TYPE.GROUP,
        fields: {
          registeredAddress: {
            title: "Registered Address",
            noExpand: true,
            type: TYPE.GROUP,
            fields: address
          },
          isSameAddress: {
            label: "Is your business address same as your Registered address?",
            type: TYPE.RADIO,
            options: {
              YES: 'Yes',
              NO: 'No'
            },
            required: true,
          },
          businessAddress: {
            title: "Business Address",
            type: TYPE.GROUP,
            noExpand: true,
            fields: address,
            visibleIfFalse: 'businessDetails.addresses.isSameAddress',
          },
        },
      },
    }
  },
  stakeholders: {
    title: "Stakeholders",
    info: 'Please provide the following information:<br /><ul><li>For Sole Traders/Partnerships - add owners details e.g. only one sole trader needed.</li><li>Two Directors/Officers listed on Companies House.</li><li>Shareholders with more than 25% ownership of the company.</li><li>If you have a Corporate Owner please enter their Stakeholder information.</li></ul> <br /> For more information on the required documents, please click <a href="https://help.travelledger.org/tl-pay-account/account-registration-process/uk-application-kyb-kyc" target="_blank">here</a> to consult our Wiki page.',
    type: TYPE.GROUP,
    multiple: true,
    fields: [stakeholdersFields]
  },
  applicantDetails: {
    title: "Applicant Details",
    info: "All clients are required to complete this section. The applicant must be an authorized representative, appointed either as a Director or an Authorized Signatory, designated by the company's Director or via Power of Attorney, to execute and sign transactions on behalf of the company.", 
    type: TYPE.GROUP,
    fields: {
      ...person,
      contactDetails: {
        title: "Applicants Contact Details",
        type: TYPE.GROUP,
        fields: contact
      },
      address: {
        title: "Applicants Address Details",
        type: TYPE.GROUP,
        info: "The applicant’s address details is where you provide your current and personal place of residence. You will need to enter your complete address, including the house number, street name, town or city, county or state, postal code.",
        fields: address
      },
      documentDetails: {
        title: "Applicants Document Details",
        info: 'Please provide the following documents, where applicable:<br /><ul><li>Photo identification</li><li>Proof of address</li><li>Letter of Authority (required only if the applicant is not a Director or Stakeholder listed above)</li></ul> <u>Please provide colour copies of any proof of identity documents. Black and white copies are not accepted. </u> <br /> <br /> For more information on the required documents, please click <a href="https://help.travelledger.org/tl-pay-account/account-registration-process/uk-application-kyb-kyc" target="_blank">here</a> to consult our Wiki page.',
        type: TYPE.GROUP,
        multiple: true,
        fields: [
          documentDetailsPerson('IDENTITY'),
          documentDetailsPerson('ADDRESS'),
          documentDetailsPerson('AUTHORITY'),
        ]
      },
      "professionalDetails.0": {
        title: "Applicants Professional Details",
        type: TYPE.GROUP,
        fields: {
          position: {
            label: "Position",
            type: TYPE.SELECT,
            options: positionUk,
            required: true
          },
          sharePercentage: {
            label: "Share Percentage",
            type: TYPE.STRING,
            decimalDigits: 2,
            info: 'Enter 0 if no shares held. Do not add the % sign.'
          },
        }
      },
    }
  },
  riskAssessmentInfo: {
    title: "Additional Business Information",
    type: TYPE.GROUP,
    fields: {
      totalEmployees: {
        label: "Total Employees",
        type: TYPE.SELECT,
        options: totalEmployees,
        required: true
      },
      annualTurnover: {
        label: "Annual Turnover",
        type: TYPE.SELECT,
        options: annualTurnoverUk,
        required: true
      },
      industrySector: {
        label: "Industry Sector",
        type: TYPE.SELECT,
        options: industrySector,
        required: true
      },
      intendedUseOfAccount: {
        label: "Intended Use Of Account",
        type: TYPE.SELECT,
        options: intendedUseOfAccount,
        required: true,
      },
      transactionCountries: {
        label: "Transaction Countries you make payments to",
        type: TYPE.COUNTRY,
        options: transactionCountriesUk,
        multiple: true,
        required: true,
        info: 'Please select countries you make payments to.'
      },
      countryOfOperation: {
        label: "Countries Of Operation",
        type: TYPE.COUNTRY,
        multiple: true,
        required: true,
        info: 'Please select countries where you have offices or operations.'
      },
      restrictedCountriesInfo: {
        label: "Do you directly or indirectly offer travel to any of the following countries:",
        info: "Afghanistan, Belarus, Burundi, Central African Republic, Cuba, Eritrea, Guinea-Bissau, Iran, Iraq, Libya, Myanmar, North Korea, Russia, Somalia, South Sudan, Sudan, Syria or Yemen?",
        type: TYPE.RADIO,
        options: {
          YES: 'Yes',
          NO: 'No'
        },
        required: true
      },
      restrictedCountries: {
        label: "Restricted Countries",
        type: TYPE.COUNTRY,
        options: restrictedCountries,
        multiple: true,
        info: "Please select all countries that apply in the drop down.",
        visibleIfTrue: 'riskAssessmentInfo.restrictedCountriesInfo',
      },
      ofacLicencePresent: {
        label: "Do you have an OFCI license or equivalent?",
        type: TYPE.RADIO,
        options: {
          YES: 'Yes',
          NO: 'No'
        },
        visibleIfTrue: 'riskAssessmentInfo.restrictedCountriesInfo',
      },
    }
  },
}
