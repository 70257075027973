import { person, contact, documentDetailsCorporateStakeholders } from './common'
import { TYPE } from '../../formTypes'
import { 
  annualTurnoverEu, 
  businessTypesEu, 
  corporateDocumentTypeEu, 
  industrySector, 
  intendedUseOfAccount, 
  personDocumentTypeEu, 
  positionEu, 
  totalEmployees 
} from '../values'
import { NIUM_STAKEHOLDER_TYPE } from 'constants.js'

export const address = {
  addressLine1: {
    label: "Address Line 1",
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  addressLine2: {
    label: "Address Line 2",
    type: TYPE.STRING,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  city: {
    label: "City",
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  country: {
    label: "Country",
    type: TYPE.COUNTRY,
    required: true,
  },
  postcode: {
    label: "Postcode",
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
      'string.pattern.base',
    ],
  },
  state: {
    label: "State",
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
}

export const documentDetailsCorporate = {
  documentType: {
    label: 'Document Type',
    type: TYPE.SELECT,
    options: corporateDocumentTypeEu,
    required: true,
  },
  "document.0.document": {
    label: "Document",
    type: TYPE.FILE,
    info: " File size limit: 1.5MB | Accepted formats: PDF, JPG, PNG",
    required: true,
  },
  documentNumber: {
    label: "Document Number",
    type: TYPE.STRING,
    required: true,
    info: 'e.g. incorporation number or passport, etc...'
  },
  documentIssuanceCountry: {
    label: "Document Issuance Country",
    type: TYPE.COUNTRY,
    required: true,
  },
}

export const documentDetailsPerson = {
  documentType: {
    label: 'Document Type',
    type: TYPE.SELECT,
    info: "Passport is required",
    options: personDocumentTypeEu,
    required: true
  },
  "document.0.document": {
    label: "Document",
    type: TYPE.FILE,
    info: "File size limit: 1.5MB | Accepted formats: PDF, JPG, PNG",
    required: true
  },
  documentNumber: {
    label: "Document Number",
    type: TYPE.STRING,
    required: true,
    info: 'e.g. incorporation number or passport, etc...'
  },
  documentIssuanceCountry: {
    label: "Document Issuance Country",
    type: TYPE.COUNTRY,
    required: true
  },
  documentExpiryDate: {
    label: "Document Expiry Date",
    info: 'Please enter fictitious dates if not applicable',
    type: TYPE.DATE,
    future: true,
  },
  documentIssuedDate: {
    label: "Document Issued Date",
    info: 'Please enter fictitious dates if not applicable',
    type: TYPE.DATE,
    past: true,
  },
  documentIssuingAuthority: {
    label: "Document Issuing Authority",
    type: TYPE.STRING,
    required: true
  },
}

export const stakeholdersFields = {
  stakeholderType: {
    type: TYPE.RADIO,
    options: {
      [NIUM_STAKEHOLDER_TYPE.INDIVIDUAL]: 'Individual',
      [NIUM_STAKEHOLDER_TYPE.CORPORATE]: 'Corporate (If owned by another company)'
    },
    required: true
  },
  businessPartner: {
    title: "Stakeholder Details",
    type: TYPE.GROUP,
    renderIf: {
      'stakeholders.index.stakeholderType': NIUM_STAKEHOLDER_TYPE.CORPORATE
    },
    fields: {
      businessName: {
        label: "Business Name",
        type: TYPE.STRING,
        required: true
      },
      businessRegistrationNumber: {
        label: "Business Registration Number",
        type: TYPE.STRING,
        required: true
      },
      businessEntityType: {
        label: "Stakeholder Type",
        type: TYPE.SELECT,
        options: positionEu,
        required: true
      },
      sharePercentage: {
        label: "Share Percentage",
        type: TYPE.STRING,
        decimalDigits: 2,
        info: 'Enter 0 if no shares held. Do not add the % sign.'
      },
      "legalDetails.registeredCountry": {
        label: "Registered Country",
        type: TYPE.COUNTRY,
        required: true
      },
      documentDetails: {
        title: "Document Details",
        type: TYPE.GROUP,
        multiple: true,
        fields: [documentDetailsCorporateStakeholders]
      },
    },
  },
  stakeholderDetails: {
    title: "Stakeholder Details",
    type: TYPE.GROUP,
    renderIf: {
      'stakeholders.index.stakeholderType': NIUM_STAKEHOLDER_TYPE.INDIVIDUAL
    },
    fields: {
      ...person,
      birthCountry: {
        label: "Birth Country",
        type: TYPE.COUNTRY,
        required: true
      },
      isPep: {
        label: "Is Politically Exposed Person",
        type: TYPE.RADIO,
        options: {
          Yes: 'Yes',
          No: 'No'
        },
        required: true
      },
      address: {
        title: "Address Details",
        type: TYPE.GROUP,
        fields: address
      },
      contactDetails: {
        title: "Contact Details",
        type: TYPE.GROUP,
        fields: {
          ...contact
        }
      },
      "professionalDetails.0": {
        title: "Professional Details",
        type: TYPE.GROUP,
        fields: {
          position: {
            label: "Position",
            type: TYPE.SELECT,
            options: positionEu
          },
          positionStartDate: {
            label: "Position Start Date",
            type: TYPE.DATE,
            past: true,
          },
          sharePercentage: {
            label: "Share Percentage",
            type: TYPE.STRING,
            decimalDigits: 2,
            info: 'Enter 0 if no shares held. Do not add the % sign.'
          },
        }
      },
      "taxDetails.0": {
        title: "Tax Details",
        type: TYPE.GROUP,
        fields: {
          country: {
            label: "Country",
            type: TYPE.COUNTRY,
            required: true,
          },
          taxNumber: {
            label: "Tax number",
            type: TYPE.STRING,
            required: true,
            customErrorMessageTypes: [
              'string.max',
            ],
          },
        },
      },
      documentDetails: {
        title: "Document Details",
        type: TYPE.GROUP,
        multiple: true,
        fields: [{
          ...documentDetailsPerson,
          documentIssuedDate: {
            label: "Document Issued Date",
            info: 'Please enter fictitious dates if not applicable',
            type: TYPE.DATE,
            past: true,
            required: true
          },
        }]
      },
    },
  },
}

export const formStructure = {
  region: {
    title: "Region",
    type: TYPE.GROUP,
    fields: {
      region: {
        label: "Region",
        type: TYPE.SELECT,
        options: { EU: 'EU' },
        required: true,
      }
    }
  },
  businessDetails: {
    title: "Business Details",
    type: TYPE.GROUP,
    fields: {
      businessName: {
        label: "Business Name",
        type: TYPE.STRING,
        required: true
      },
      businessRegistrationNumber: {
        label: "Business Registration Number",
        type: TYPE.STRING,
        required: true
      },
      tradeName: {
        label: "Trade Name",
        type: TYPE.STRING,
        required: true
      },
      website: {
        label: "Website",
        type: TYPE.STRING
      },
      businessType: {
        label: "Business Type",
        type: TYPE.SELECT,
        options: businessTypesEu,
        required: true
      },
      documentDetails: {
        title: "Document Details",
        info: "For NIUM to perform electronic verification of company data, the following information is required where applicable. <b>Note</b>: Documents must be uploaded in PDF, JPG, or PNG format:<br/><ul><li><b>For UK Entities</b>: Company Extract or Certificate of Incorporation & Proof of Business Address (if different from Operations Address).</li><li><b>Corporate Documents (Required for all entities)</b>: 1. Certificate of Incorporation, 2. Business Registration Certificate or equivalent, 3. Memorandum & Articles of Incorporation, 4. Latest Board Resolution (if applicable), 5, Certificate of Incumbency (if applicable) 6. Share Registry (if applicable).</li><li><b>Multiple Ownership Layers</b>: Provide an organization chart with shareholding structure and UBOs with percentages, signed and dated by an authorized representative.</li><li><b>Mandatory Documents for US Entities</b>: Articles of Incorporation and evidence of Good Standing.</li></ul>",
        type: TYPE.GROUP,
        multiple: true,
        fields: [documentDetailsCorporate]
      },
      legalDetails: {
        title: "Legal Registration Details",
        type: TYPE.GROUP,
        fields: {
          registeredDate: {
            label: "Registration Date",
            type: TYPE.DATE,
            past: true,
            info: "The date the business was originally registered",
            required: true
          },
          registeredCountry: {
            label: "Registered Country",
            type: TYPE.COUNTRY,
            required: true
          },
        }
      },
      "taxDetails.0": {
        title: "Tax Details",
        type: TYPE.GROUP,
        info: "If your company is a subsidiary or a part of a group of companies, provide the country where taxes are paid for your own legal entity and not for the entire group of companies.",
        fields: {
          country: {
            label: "Country",
            type: TYPE.COUNTRY,
            options: ['AT', 'BE', 'HR', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'ES'],
            required: true
          },
          taxNumber: {
            label: "Tax number",
            type: TYPE.STRING,
            required: true
          },
        },
      },
      "addresses": {
        title: "Addresses",
        type: TYPE.GROUP,
        fields: {
          registeredAddress: {
            title: "Registered Address",
            noExpand: true,
            type: TYPE.GROUP,
            fields: address
          },
          isSameAddress: {
            label: "Is your business address same as your Registered address?",
            type: TYPE.RADIO,
            options: {
              YES: 'Yes',
              NO: 'No'
            },
            required: true,
          },
          businessAddress: {
            title: "Business Address",
            noExpand: true,
            type: TYPE.GROUP,
            fields: address,
            visibleIfFalse: 'businessDetails.addresses.isSameAddress',
          },
        },
      },
    }
  },
  stakeholders: {
    title: "Stakeholders",
    info: "Stakeholders include the following roles, and all applicable persons must be added and completed. <b>Note</b>: Documents must be uploaded in PDF, JPG, or PNG format:<br /><ul><li><b>Directors (UK/EU)</b>: Include at least two Directors managing day-to-day affairs.</li><li><b>Control Person</b>: For US, please include one control person. For UK/EU, include all individuals with significant control over the company.</li><li><b>Individual Shareholders/UBOs</b>: UBOs must be natural persons (individuals), except for publicly traded businesses. List UBOs with 25% ownership or more (or 10% or more for PEPs). US persons only: Select social security number for Government ID Type and provide the last four digits of SSN under the “Government ID Number” section. Note: If the stakeholder’s position is UBO, then the share percentage is a required input parameter.</li></ul>",
    type: TYPE.GROUP,
    multiple: true,
    fields: [stakeholdersFields]
  },
  applicantDetails: {
    title: "Applicant Details",
    info: "All clients are required to complete this section. The applicant must be an authorized representative, appointed either as a Director or an Authorized Signatory, designated by the company's Director or via Power of Attorney, to execute and sign transactions on behalf of the company.",
    type: TYPE.GROUP,
    fields: {
      ...person,
      birthCountry: {
        label: "Birth Country",
        type: TYPE.COUNTRY,
        required: true
      },
      isPep: {
        label: "Is Politically Exposed Person",
        type: TYPE.RADIO,
        options: {
          Yes: 'Yes',
          No: 'No'
        },
        required: true
      },
      contactDetails: {
        title: "Applicants Contact Details",
        type: TYPE.GROUP,
        fields: contact
      },
      address: {
        title: "Applicants Address Details",
        type: TYPE.GROUP,
        info: "The applicant’s address details is where you provide your current and personal place of residence. You will need to enter your complete address, including the house number, street name, town or city, county or state, postal code.",
        fields: address
      },
      documentDetails: {
        title: "Applicants Document Details",
        info: "Clients must provide the following documents where applicable. <b>Note</b>: Documents must be uploaded in PDF, JPG, or PNG format:<br /><ul><li><b>Photo Identification/Proof of Address</b>: Please provide one form of photo identification (e.g., passport or driving license). If the identification does not include a residential address, a proof of address (e.g., utility bill) must also be provided.</li><li><b>For Letter of Authority</b>: If not a director of the company, an additional document, Certified Letter of Authority, must also be provided and signed by a director.</li></ul>",
        type: TYPE.GROUP,
        multiple: true,
        fields: [documentDetailsPerson]
      },
      "professionalDetails.0": {
        title: "Applicants Professional Details",
        type: TYPE.GROUP,
        fields: {
          position: {
            label: "Position",
            type: TYPE.SELECT,
            options: positionEu,
            required: true
          },
          positionStartDate: {
            label: "Position Start Date",
            type: TYPE.DATE,
            past: true,
            required: true
          },
          sharePercentage: {
            label: "Share Percentage",
            type: TYPE.STRING,
            decimalDigits: 2,
            info: 'Enter 0 if no shares held. Do not add the % sign.'
          },
        }
      },
    }
  },
  riskAssessmentInfo: {
    title: "Additional Business Information",
    type: TYPE.GROUP,
    fields: {
      totalEmployees: {
        label: "Total Employees",
        type: TYPE.SELECT,
        options: totalEmployees,
        required: true
      },
      annualTurnover: {
        label: "Annual Turnover",
        type: TYPE.SELECT,
        options: annualTurnoverEu,
        required: true
      },
      industrySector: {
        label: "Industry Sector",
        type: TYPE.SELECT,
        options: industrySector,
        required: true
      },
      intendedUseOfAccount: {
        label: "Intended Use Of Account",
        type: TYPE.SELECT,
        options: intendedUseOfAccount,
        required: true,
      },
      transactionCountries: {
        label: "Transaction Countries you make payments to",
        type: TYPE.COUNTRY,
        multiple: true,
        required: true,
        info: 'Please select countries you make payments to.'
      },
      countryOfOperation: {
        label: "Countries Of Operation",
        type: TYPE.COUNTRY,
        multiple: true,
        required: true,
        info: 'Please select countries where you have offices or operations.'
      },
      
    }
  },
}
