import { useStyles } from '../styles'
import React, {
  useState,
  useEffect,
} from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import { Typography } from '@material-ui/core'
import FormSelect from 'components/FormSelect'

const SelectComponent = ({ nameId, formItem: { label, options, required, disabled, info, defaultValue } }) => {
  const classes = useStyles()
  const {
    control,
    setValue,
  } = useFormContext()
  const [show, setShow] = useState(true)
  const [defaultLoaded, setDefaultLoaded] = useState(false)
  const isOnlyOption = Object.keys(options).length === 1

  useEffect(() => {
    if (!show) {
      setShow(true)
    }
  }, [show])

  useEffect(() => {
    if (!defaultLoaded) {
      if (isOnlyOption) {
        setValue(nameId, Object.keys(options)[0])
      }
      if(defaultValue) {
        setValue(nameId, defaultValue)
      }
      setDefaultLoaded(true)
    }
  }, [defaultLoaded, isOnlyOption, nameId, options, setValue, defaultValue])

  if (!show) {
    return <></>
  }

  return (
    <Controller
      render={({ field, fieldState }) => (
        <>
          <FormSelect
            {...field}
            {...((isOnlyOption || disabled) && { disabled: true })}
            label={`${label} ${required ? '*' : ''}`}
            keyTitle={options}
            variant="outlined"
            className={classes.field}
            fullWidth
            dense
            error={fieldState.error && 'Is Required'}
            endAdornment={
              isOnlyOption ? <></> :
              <InputAdornment position="end" className={classes.selectClear}>
                <IconButton
                  disableRipple
                  onClick={() => {
                    setShow(false)
                    setValue(nameId, '')
                  }}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  <ClearIcon fontSize="small" color="disabled" />
                </IconButton>
              </InputAdornment>
            }
          />
          {info && <Typography color="textSecondary" className={classes.info} dangerouslySetInnerHTML={{ __html: info }} />}
        </>
      )
      }
      control={control}
      name={nameId}
      error={false}
    />
  )
}

export default SelectComponent