import React from 'react'

import DataGrid from 'components/DataGrid'
import { currencyFormatter, DEFAULT_COLUMN_WIDTH_VALUES, FILTER_TYPES } from 'components/DataGrid/utils'
import { initialFilterValues, STATUS_FILTER_OPTIONS } from 'containers/PaymentsStatement/constants'
import { useState } from 'react'
import { PAYMENT_METHOD_FILTER_OPTIONS } from '../constants'
import CommercialLinkNetsTable from './CommercialLinkNetsTable'

import { API_URL } from 'constants.js'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { filterValuesToQueryParams } from '../utils'
import { RefreshButton } from 'components/DataGrid/components/ActionButtons'
import SummaryExportButton from './SummaryExportButton'
import { CollectionDateCell } from 'components/TransactionsTable/components'
import { useIntl } from 'react-intl'
import kebabCase from 'lodash/kebabCase'

const CompanySettlementSummariesTable = () => {
  const [filters, setFilters] = useState(initialFilterValues)
  const queryParams = filterValuesToQueryParams(filters)
  const { formatMessage } = useIntl()

  const { isLoading, data, refetch } = useQuery(
    'transactions-summaries',
    () => get(`${API_URL}/payments-statement/transactions-summaries`, queryParams),
    {
      enabled: true,
      retry: false,
    },
  )

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const Amount = ({ amount, currency }) => {
    return currencyFormatter({ value: amount, currency })
  }

  const columns = [
    {
      title: formatMessage({ id: 'payments-statement.payment-method'}),
      name: 'paymentPlatform',
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(PAYMENT_METHOD_FILTER_OPTIONS)
              .map(([k, v]) => [k, formatMessage({ id: `payment-method-filter-options.${kebabCase(v)}` })])
          ),
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      title: formatMessage({ id: 'payments-statement.company'}),
      name: 'companyName',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      title: formatMessage({ id: 'payments-statement.submission'}),
      name: 'submissionAt',
      type: 'date-time',
      filter: {
        type: FILTER_TYPES.DATE,
      },
      getCellValue: row => <CollectionDateCell date={row.submissionAt} />,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      title: formatMessage({ id: 'payments-statement.bank-date'}),
      name: 'bankDate',
      type: 'date-time',
      filter: {
        type: FILTER_TYPES.DATE,
      },
      getCellValue: row => <CollectionDateCell date={row.bankDate} />,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      title: formatMessage({ id: 'payments-statement.counterparty'}),
      name: 'processingEntityName',
      getCellValue: row => row.processingEntityName || <i>Expand to view details</i>,
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      title: formatMessage({ id: 'payments-statement.net-amount'}),
      name: 'netAmountInCents',
      type: 'currency',
      getCellValue: row => <Amount amount={row.netAmountInCents} currency={row.currency} />,
      filter: {
        type: FILTER_TYPES.CURRENCY,
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.L
    },
    {
      title: formatMessage({ id: 'payments-statement.status'}),
      name: 'status',
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(STATUS_FILTER_OPTIONS)
              .map(([k, v]) => [k, formatMessage({ id: `payment-statement-status.${kebabCase(v)}` })]),
          ),
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      title: formatMessage({ id: 'payments-statement.export'}),
      name: 'export',
      filter: false,
      getCellValue: (rowData) => <SummaryExportButton companySettlementSummaryId={rowData.id} />,
    },
  ]
    .filter(Boolean)
    .map((column) => ({
      ...column,
      getCellValueToExport: column.name ? (row) => row[column.name] : undefined,
    }))

  return (
    <DataGrid
      virtualTable
      data={
        data?.data?.map((e) => {
          return {
            ...e,
            debitAmount: e.type === 'debit' ? e.bankAmount : null,
            creditAmount: e.type === 'credit' ? e.bankAmount : null,
            bankDate: e.paymentAt,
          }
        }) || []
      }
      name={'companySettlementSummariesTable'}
      fullRemainingWindowHeight
      isLoading={isLoading}
      filtering={{
        defaultFilters: initialFilterValues,
        filters,
        onChange: (newValue) => {
          setFilters(newValue)
        },
        clearFilters: () => {
          setFilters(initialFilterValues)
        },
      }}
      pagination={{ pageSizes: [20, 50, 100], pageSize: 20 }}
      columns={columns}
      totalNumberOfItems={data?.length}
      extra={<RefreshButton onClick={refetch} />}
      {...{
        tableMessages: {
          noData: formatMessage({ id: 'table.no-data' }),
        },
      }}
      RowDetail={(row) => <CommercialLinkNetsTable summaryDetails={row.row} />}
      isRowExpandable={() => true}
    />
  )
}

export default CompanySettlementSummariesTable
