import Modal from 'components/Modal'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import Csv from 'containers/CSVMenu/components/csv'
import { Typography, Grid, Button, Link, Zoom } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useStyles } from './styles'
import { ROUTE_URL } from 'constants.js'
import { useHistory } from 'react-router'

const UploadModal = ({ isOpen, onCloseClick, uploadCSV, setUploadCSVError, csv, onSuccess }) => {
  const modalRef = useRef()
  const history = useHistory()
  const [successContentIsOpen, setSuccessContentIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.open()
    }
  }, [isOpen])

  useEffect(() => {
    if (csv.success) {
      setUploadCSVError(null)
      setSuccessContentIsOpen(true)
    }
  }, [csv.success, setSuccessContentIsOpen, setUploadCSVError])

  const handleSuccessMessageClose = () => {
    onCloseClick()
    setSuccessContentIsOpen(false)
    modalRef.current.close()
    onSuccess()
  }

  const classes = useStyles()

  return (<>
    <Modal
      ref={modalRef}
      defaultOpened={isOpen}
      onClose={handleSuccessMessageClose}
    >
      {successContentIsOpen ?
        <Zoom in={successContentIsOpen} timeout={300}>
          <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Grid item>
              <CheckCircleOutlineIcon className={classes.icon} />
            </Grid>
            <Grid item>
              <Typography variant='h5'>Upload in Progress</Typography>
            </Grid>
            <Grid item className={classes.contentContainer}>
              <Typography variant="body1">You can also track upload status in <Link
                component="button"
                variant="body1"
                underline="always"
                onClick={() => {
                  history.push(ROUTE_URL.fileDataDelivery)
                }}
              >
                Data Delivery
              </Link></Typography>
              <Typography variant="body1">A <Link
                component="button"
                variant="body1"
                underline="always"
                onClick={() => {
                  history.push(ROUTE_URL.notifications)
                }}
              >
                notification
              </Link> will be sent to your email with more details once processed</Typography>
            </Grid>
            <Grid item container className={classes.buttonsContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSuccessMessageClose}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Zoom>
        :
        <Csv
          uploadCSV={uploadCSV}
          setUploadCSVError={setUploadCSVError}
          csv={csv}
          onCancel={() => {
            setUploadCSVError(null)
            modalRef.current.close()
            onCloseClick()
          }} />
      }
    </Modal>
  </>)
}

UploadModal.propTypes = {
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
  uploadCSV: PropTypes.func.isRequired,
  setUploadCSVError: PropTypes.func.isRequired,
  csv: PropTypes.object.isRequired
}

export default UploadModal
