import { joiResolver } from '@hookform/resolvers/joi'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Radio from '@material-ui/core/Radio'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { DAYS_OF_MONTH_KEY_TITLE, FREQUENCY_TYPE_KEY_TITLE, MAX_REFERENCE_MSG_LEN, scheduledWithdrawalDefaultValues, scheduledWithdrawalFormSchema } from '../withdrawalValidationSchema'
import { Button, Checkbox, DialogActions, FormControlLabel, FormHelperText, MenuItem, OutlinedInput, RadioGroup, Select, Switch, TextField, Typography, useTheme } from '@material-ui/core'
import CheckboxGroup from 'components/CheckboxGroup'
import { SCHEDULE_DAY_KEY_TITLE } from '../../../constants'
import InputText from 'components/ui/Form/InputText'
import { useIntl } from 'react-intl'
import { kebabCase } from 'lodash'
import { ScheduledWithdrawalDayOfMonth, ScheduledWithdrawalFrequencyType } from '@travel-ledger/type-constants'

const ScheduledWithdrawalDialog = ({
  open,
  onClose,
  whitelist,
  onSubmit,
  scheduledWithdrawal,
}) => {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const isEditMode = !!scheduledWithdrawal?.id && !!scheduledWithdrawal?.configuration
  const {
    register,
    setValue,
    watch,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    control
  } = useForm({
    resolver: joiResolver(scheduledWithdrawalFormSchema()),
    defaultValues: {
      ...scheduledWithdrawalDefaultValues,
      ...(isEditMode && {
        id: scheduledWithdrawal.id,
        account: scheduledWithdrawal.account,
        amount: scheduledWithdrawal.configuration.amount,
        reference: scheduledWithdrawal.configuration.reference,
        frequencyType: scheduledWithdrawal.configuration.frequencyType,
        daysOfWeek: scheduledWithdrawal.configuration.daysOfWeek,
        daysOfMonth: scheduledWithdrawal.configuration.daysOfMonth,
        timeOfDay: scheduledWithdrawal.configuration.timeOfDay,
        minAvailableFunds: scheduledWithdrawal.configuration.minAvailableFunds,
        minAmountLeft: scheduledWithdrawal.configuration.minAmountLeft,
        withdrawAllAvailableFunds: scheduledWithdrawal.configuration.withdrawAllAvailableFunds,
        minAvailableFundsEnabled: scheduledWithdrawal.configuration.minAvailableFundsEnabled,
        minAmountLeftEnabled: scheduledWithdrawal.configuration.minAmountLeftEnabled,
      }),
    },
  })

  const {
    account: selectedWhitelistAccount,
    minAvailableFundsEnabled,
    minAmountLeftEnabled,
    withdrawAllAvailableFunds,
    daysOfWeek,
    daysOfMonth,
    frequencyType,
  } = watch()

  const handleSubmit = handleFormSubmit(onSubmit)

  useEffect(() => {
    setValue('minAmountLeftEnabled', false)
    setValue('minAmountLeft', undefined)
    setValue('amount', undefined)
  }, [withdrawAllAvailableFunds, setValue])

  useEffect(() => {
    if (!minAmountLeftEnabled) {
      setValue('minAmountLeft', undefined)
    }
  }, [minAmountLeftEnabled, setValue])

  useEffect(() => {
    if (!minAvailableFundsEnabled) {
      setValue('minAvailableFunds', undefined)
    }
  }, [minAvailableFundsEnabled, setValue])

  useEffect(() => {
    if (frequencyType === ScheduledWithdrawalFrequencyType.WEEKLY) {
      setValue('daysOfMonth', [])
    } else if (frequencyType === ScheduledWithdrawalFrequencyType.MONTHLY) {
      setValue('daysOfWeek', [])
    } else if (frequencyType === ScheduledWithdrawalFrequencyType.DAILY) {
      setValue('daysOfMonth', [])
      setValue('daysOfWeek', [])
    }
  }, [frequencyType, setValue])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <Box display="flex" m={2}>
          <Box flexGrow={0} pr={4}>
            <Box pb={2}>
              <Typography component="h5" variant="h5">
                {formatMessage({ id: 'scheduled-withdrawal-dialog.choose-account-to-withdraw-to' })}
              </Typography>
            </Box>
            <List>
              {whitelist.map((beneficiary, index) => {
                return (
                  <ListItem key={index} style={{ border: `1px solid ${theme.palette.divider}` }}>
                    <ListItemIcon>
                      <Radio
                        checked={selectedWhitelistAccount === beneficiary.beneficiaryHashId}
                        onChange={() => setValue('account', beneficiary.beneficiaryHashId)}
                        value={beneficiary.beneficiaryHashId}
                        {...register('account')}
                      />
                    </ListItemIcon>
                    <ListItemText primary={beneficiary.beneficiaryAccountNumber} secondary={`${beneficiary.beneficiaryName} / ${beneficiary.beneficiaryBankName} / ${beneficiary.destinationCurrency}`} />
                  </ListItem>
                )
              })}
            </List>
            <FormHelperText error>{errors?.account?.message}</FormHelperText>
          </Box>
          <Box flexGrow={1}>
            <Box pb={2}>
              <Typography component="h5" variant="h5">
                {formatMessage({ id: 'scheduled-withdrawal-dialog.schedule-details' })}
              </Typography>
            </Box>

            <Box pt={2}>
              <Typography component="h6" variant="h6">
                {formatMessage({ id: 'scheduled-withdrawal-dialog.when-select-frequency' })}:*
              </Typography>
              <Controller
                control={control}
                name="frequencyType"
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    {Object.entries(FREQUENCY_TYPE_KEY_TITLE).map(([k, v]) => (
                      <FormControlLabel
                        key={k}
                        value={k}
                        control={<Radio />}
                        label={formatMessage({ id: `withdrawal-tab.${kebabCase(v)}` })}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
              <FormHelperText error>{errors?.frequencyType?.message}</FormHelperText>
            </Box>

            {frequencyType === ScheduledWithdrawalFrequencyType.WEEKLY && (
              <Box pt={2}>
                <Typography component="h6" variant="h6">
                  {formatMessage({ id: 'scheduled-withdrawal-dialog.select-day-s' })}:*
                </Typography>
                <Controller
                  name="daysOfWeek"
                  control={control}
                  render={({ field }) => (
                    <CheckboxGroup
                      {...field}
                      type="number"
                      items={
                        Object.entries(SCHEDULE_DAY_KEY_TITLE)
                          .map(([key, value]) => ({ value: parseInt(key), label: formatMessage({ id: `schedule-day-key-title.${kebabCase(value)}` }) }))
                      }
                      onChange={days => setValue('daysOfWeek', days)}
                      value={daysOfWeek}
                      error={!!errors?.daysOfWeek}
                    />
                  )}
                />
                <FormHelperText error>{errors?.daysOfWeek?.message}</FormHelperText>
              </Box>
            )}

            {frequencyType === ScheduledWithdrawalFrequencyType.MONTHLY && (
              <Box pt={2}>
                <Typography component="h6" variant="h6">
                  {formatMessage({ id: 'scheduled-withdrawal-dialog.select-day-s' })}:*
                </Typography>
                <Controller
                  name="daysOfMonth"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      multiple
                      value={daysOfMonth}
                      onChange={e => setValue('daysOfMonth', e.target.value)}
                      input={<OutlinedInput label={formatMessage({ id: 'scheduled-withdrawal-dialog.days' })} />}
                      renderValue={(selected) => selected.map(x => typeof x === 'number' ? x : formatMessage({ id: `withdrawal-tab.${kebabCase(DAYS_OF_MONTH_KEY_TITLE[x])}` })).join(', ')}
                      fullWidth
                    >
                      {[ScheduledWithdrawalDayOfMonth.LAST, ...new Array(31).fill().map((_, i) => 1 + i)].map((day) => (
                        <MenuItem key={day} value={day}>
                          <Checkbox checked={daysOfMonth.includes(day)} />
                          <ListItemText primary={typeof day === 'number' ? day : formatMessage({ id: `withdrawal-tab.${kebabCase(DAYS_OF_MONTH_KEY_TITLE[day])}` })} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText error>{errors?.daysOfMonth?.message}</FormHelperText>
              </Box>
            )}

            <Box pt={2}>
              <Typography component="h6" variant="h6">
                {formatMessage({ id: 'scheduled-withdrawal-dialog.select-time-of-day' })}:*
              </Typography>
              <Box width="8rem">
                <Controller
                  control={control}
                  name="timeOfDay"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={formatMessage({ id: 'scheduled-withdrawal-dialog.time-of-day' })}
                      type="time"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 60,
                      }}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <FormHelperText error>{errors?.timeOfDay?.message}</FormHelperText>
            </Box>

            <Box pt={2}>
              <Typography component="h6" variant="h6">
                {formatMessage({ id: 'scheduled-withdrawal-dialog.choose-scheduled-amount-to-be-withdrawn' })}:*
              </Typography>
              <Box>
                <InputText
                  required={!withdrawAllAvailableFunds}
                  errors={errors}
                  type="number"
                  name="amount"
                  control={control}
                  label={formatMessage({ id: 'scheduled-withdrawal-dialog.amount' })}
                  disabled={withdrawAllAvailableFunds}
                />
                <Box display="inline-block" pl={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={withdrawAllAvailableFunds}
                        onChange={(_, checked) => setValue('withdrawAllAvailableFunds', checked)}
                      />
                    }
                    label={formatMessage({ id: 'scheduled-withdrawal-dialog.withdraw-all-available-funds' })}
                  />
                </Box>
              </Box>
              <InputText
                errors={errors}
                type="text"
                name="reference"
                control={control}
                label={formatMessage({ id: 'scheduled-withdrawal-dialog.reference-optional' })}
                inputProps={{ maxLength: MAX_REFERENCE_MSG_LEN }}
              />
            </Box>

            <Box pt={2}>
              <Typography component="h6" variant="h6">
                {formatMessage({ id: 'scheduled-withdrawal-dialog.schedule-rules' })}:
              </Typography>
              <Box pt={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={minAvailableFundsEnabled}
                      onChange={(_, checked) => setValue('minAvailableFundsEnabled', checked)}
                    />
                  }
                  label={formatMessage({ id: 'scheduled-withdrawal-dialog.withdraw-only-when-available-funds-reach' }) + ':'}
                />
                <InputText
                  required={minAvailableFundsEnabled}
                  errors={errors}
                  type="number"
                  name="minAvailableFunds"
                  control={control}
                  label={formatMessage({ id: 'scheduled-withdrawal-dialog.amount' })}
                  disabled={!minAvailableFundsEnabled}
                />
              </Box>

              <Box pt={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={minAmountLeftEnabled}
                      onChange={(_, checked) => setValue('minAmountLeftEnabled', checked)}
                      disabled={withdrawAllAvailableFunds}
                    />
                  }
                  label={formatMessage({ id: 'scheduled-withdrawal-dialog.leave-minimum-balance-of' }) + ':'}
                />
                <InputText
                  required={minAmountLeftEnabled}
                  errors={errors}
                  type="number"
                  name="minAmountLeft"
                  control={control}
                  label={formatMessage({ id: 'scheduled-withdrawal-dialog.amount' })}
                  disabled={withdrawAllAvailableFunds || !minAmountLeftEnabled}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          color="default"
        >
          {formatMessage({ id: 'scheduled-withdrawal-dialog.cancel' })}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
        >{
          isEditMode
            ? formatMessage({ id: 'scheduled-withdrawal-dialog.save' })
            : formatMessage({ id: 'scheduled-withdrawal-dialog.create' })
        }</Button>
      </DialogActions>
    </Dialog>
  )
}

ScheduledWithdrawalDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  whitelist: PropTypes.arrayOf(PropTypes.object),
}

export default ScheduledWithdrawalDialog
