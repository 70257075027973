import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ConfirmDialog from 'components/ConfirmDialog'

import {
  SETTLEMENT_STATUS_VALUES_TO_NAME,
  INVOICE_STATUSES_BUYER_TO_NAME,
  INVOICE_STATUSES_SELLER_TO_NAME,
  ROUTE_URL,
  STATUSES,
  REASON_CODE_TO_DESCRIPTION,
  INVOICE_TAGS,
  QUICK_VIEWS,
  ACCOUNT_ROLE,
  CURRENCY_CODE,
  CURRENCY_CODE_TO_NAME,
  FEATURE_PAGINATION_ENABLED,
  TransTypeDescription,
} from 'constants.js'
import { getTagsOptionsBooking, filterTags } from 'utils/tags'
import AuthView from 'containers/AuthView/connect'
import { BreadcrumbMenuTransactions } from 'containers/BreadcrumbMenu'
import SnackbarComponent from 'components/Snackbar'
import TransactionsTable from 'components/TransactionsTable'
import InnerTransactionDetailsTable from 'components/TransactionsTable/InnerTransactionDetailsTable'
import { FILTER_TYPES, DEFAULT_COLUMN_WIDTH_VALUES } from 'components/DataGrid/utils'
import { dateToReadableDate, dateToReadableDateTime } from 'utils/date'
import {
  BookingRefCell,
  CollectionDateCell,
  DepartureDateCell,
  InvoiceStatusCell,
  OriginalAmountCell,
  AmountCell,
  TagCell,
  SettlementStatusCell,
  CommercialLinkDisplayNameCell,
} from 'components/TransactionsTable/components'
import { RefreshButton } from 'components/DataGrid/components/ActionButtons'
import styles from '../CSVMenu/styles.module.css'
import {
  columnChooserGroups,
  initialFilterValues,
  DEFAULT_HIDDEN_COLUMNS,
  REDACTED_FIELD,
} from './constants.js'
import {
  filterLocalInvoices,
  filterValuesToQueryParams,
  parseInvoices,
  updateFiltersOnQuickViewChange,
  getQuickViewRole,
  isCurrentWeekQuickView,
} from './utils.js'
import {
  BookingStatusEditField,
  CurrentAmountEditField,
  CurrentAmountCell,
  ReasonCodeCell,
  ReasonCodeEditField,
} from './components'
import { DATE_RANGES } from 'components/DataGrid/constants'
import QuickViewsDropdown from './components/QuickViewsDropdown/QuickViewsDropdown'
import useLocalStorage from 'utils/useLocalStorage'
import { useGridTitleWithHelpPopup } from 'components/HelpPopup/HelpPopup'
import { useQuery } from 'react-query'
import { getCompanies } from 'services/companiesApi'
import RedactedDateCell from 'components/TransactionsTable/components/RedactedDateCell'
import Spinner from 'containers/App/components/Spinner/Spinner'
import { formatAmountForExport } from 'utils/functions'
import { useIntl } from 'react-intl'
import kebabCase from 'lodash/kebabCase'

const BookingsReview = ({
  location,
  redirectTo,
  currUserCompanyId,
  fetchInvoices,
  invoices,
  updateInvoice,
  updateInvoices,
  clearInvoices,
  snackbar,
  selectInvoiceForAddRemittance,
  currUserCompany,
  fetchLatestCycles,
  latestCycleStartDates,
  latestDisbursementDates,
  latestCollectionPayments,
  latestDisbursementPayments,
  lastUpdate,
}) => {
  const [filters, setFilters] = useState(initialFilterValues)
  const isProcessingEntity = !!currUserCompany && !!currUserCompany.id && currUserCompany.isProcessingEntity
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmCallback, setConfirmCallback] = useState(null)
  const invoicesInPage = (FEATURE_PAGINATION_ENABLED ? invoices?.data : invoices) || []
  const [quickViewStorage, setQuickViewStorage] = useLocalStorage(`@tldg:quickView`, {
    [ACCOUNT_ROLE.BUYER]: QUICK_VIEWS.BUYER_CURRENT_WEEK,
    [ACCOUNT_ROLE.PROCESSING_ENTITY]: QUICK_VIEWS.PE_CURRENT_WEEK,
  })
  const [quickView, setQuickView] = useState(null)
  const { formatMessage } = useIntl()

  const { data: processingEntities } = useQuery(
    'processing-entities-filter',
    () => getCompanies({ 'isProcessingEntity[eq]': true }),
    {
      enabled: !!currUserCompany && !!currUserCompany.id && !currUserCompany.isProcessingEntity,
      retry: false,
    },
  )

  const columnDisableEditing = ({ row }) =>
    getQuickViewRole(quickView) !== ACCOUNT_ROLE.BUYER ||
    row.isLocked ||
    row.tags.includes(INVOICE_TAGS.PENDING_COMMERCIAL_LINK)
  
  const isQuickViewSelectedPast = quickView &&
    [QUICK_VIEWS.BUYER_PAST, QUICK_VIEWS.SELLER_PAST, QUICK_VIEWS.PE_PAST].indexOf(quickView) > -1

  // We parse invoices to derive fields to 1st level of invoice because sorting on nested props is not working
  let filteredInvoices, totalNumberOfInvoices, bookingsCurrency, isAllBookingsSameCurrency
  // timezone can be '' - in this case pass undefined to trigger the default in the utcToTimezone function
  const parsedInvoices = parseInvoices(invoicesInPage, currUserCompany.timezone || undefined)

  if (!FEATURE_PAGINATION_ENABLED) {
    filteredInvoices = filterLocalInvoices(parsedInvoices, filters, quickView)
    totalNumberOfInvoices = filteredInvoices.length
    bookingsCurrency = filteredInvoices?.[0]?.currency
    isAllBookingsSameCurrency = filteredInvoices?.every((invoice) => invoice.currency === bookingsCurrency)
  } else {
    bookingsCurrency = invoicesInPage?.[0]?.currency
    isAllBookingsSameCurrency = invoicesInPage?.every((invoice) => invoice.currency === bookingsCurrency)
  }

  const columns = [
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.tags' }),
      name: 'tags',
      // eslint-disable-next-line react/display-name
      getCellValue: (row) => <TagCell tags={filterTags(row.tags)} />,
      getCellValueToExport: (row) => (filterTags(row.tags).length ? '!' : ''),
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: getTagsOptionsBooking(),
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.XS,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.seller-name' }),
      name: 'sellerName',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.seller-id-type' }),
      titleToExport: 'Seller_ID_Type',
      name: 'importedSourceIdType',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.seller-id' }),
      name: 'importedSourceId',
      titleToExport: 'Seller_ID',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.XS,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.buyer-name' }),
      name: 'buyerName',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.buyer-id-type' }),
      name: 'importedTargetIdType',
      titleToExport: 'Buyer_ID_Type',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.buyer-id' }),
      name: 'importedTargetId',
      titleToExport: 'Buyer_ID',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.XS,
    },
    {
      title: formatMessage({ id: 'booking-review.processing-entity-company-name' }),
      name: 'processingEntityCompanyName',
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: !isProcessingEntity
            ? processingEntities?.reduce(
                (r, i) => {
                  return Object.assign(r, { [i.name]: i.name })
                },
                { Direct: 'Direct' },
              ) || { Direct: 'Direct' }
            : { [currUserCompany.name]: currUserCompany.name },
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.booking-ref' }),
      // eslint-disable-next-line react/display-name
      getCellValue: (row) => <BookingRefCell bookingRef={row.bookingRef} tags={filterTags(row.tags)} />,
      getCellValueToExport: (row) => row.bookingRef,
      name: 'bookingRef',
      titleToExport: 'Booking_Ref',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.S,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.lead-name' }),
      name: 'leadName',
      filter: true,
      disabled: isProcessingEntity,
      width: DEFAULT_COLUMN_WIDTH_VALUES.S,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.settlement-status' }),
      name: 'settlementStatus',
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(SETTLEMENT_STATUS_VALUES_TO_NAME)
              .map(([k, v]) => [k, formatMessage({ id: `settlement-status.${kebabCase(v)}` })])
          ),
        },
      },
      getCellValue: (row) => <SettlementStatusCell settlementStatus={row.settlementStatus} />,
      getCellValueToExport: (row) => row.settlementStatus,
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      wordWrap: true,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.buyer-review-end' }),
      name: 'cycleStartAt',
      filter: {
        type: FILTER_TYPES.DATE,
        predefinedRanges: latestCycleStartDates,
      },
      getCellValue: (row) =>
        row?.cycleStartAt?._i === REDACTED_FIELD ? (
          <RedactedDateCell />
        ) : (
          <CollectionDateCell date={row.cycleStartAt} settlementStatus={row.settlementStatus} />
        ),
      getCellValueToExport: (row) => dateToReadableDateTime(row.cycleStartAt),
      width: DEFAULT_COLUMN_WIDTH_VALUES.XL,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.collection-date' }),
      name: 'collectionPaymentAt',
      filter: {
        type: FILTER_TYPES.DATE,
        predefinedRanges: latestCollectionPayments,
        disableClearButton: !FEATURE_PAGINATION_ENABLED && isQuickViewSelectedPast,
      },
      getCellValue: (row) =>
        row?.collectionPaymentAt?._i === REDACTED_FIELD ? (
          <RedactedDateCell />
        ) : (
          <CollectionDateCell date={row.collectionPaymentAt} settlementStatus={row.settlementStatus} />
        ),
      getCellValueToExport: (row) => dateToReadableDateTime(row.collectionPaymentAt),
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.payment-submission' }),
      name: 'disbursementAt',
      // TODO predefined ranges does not take into account that it could be a collection/first-phase date (direct payment)
      filter: { type: FILTER_TYPES.DATE, predefinedRanges: latestDisbursementDates },
      getCellValue: (row) => {
        // NOTE when there is no second phase, the payment submission is the collection/first-phase too (direct payment)
        const date = row.disbursementAt || row.collectionAt

        if (date?._i === REDACTED_FIELD) {
          return <RedactedDateCell />
        }

        return <CollectionDateCell date={date} settlementStatus={row.settlementStatus} />
      },
      // NOTE when there is no second phase, the payment submission is the collection/first-phase too (direct payment)
      getCellValueToExport: (row) => dateToReadableDateTime(row.disbursementAt || row.collectionAt),
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.payment-date' }),
      name: 'disbursementPaymentAt',
      // TODO predefined ranges does not take into account that it could be a collection/first-phase payment date (direct payment)
      filter: { type: FILTER_TYPES.DATE, predefinedRanges: latestDisbursementPayments },
      getCellValue: (row) => {
        // NOTE when there is no second phase, the payment is the collection/first-phase payment too (direct payment)
        const date = row.disbursementPaymentAt || row.collectionPaymentAt

        if (date?._i === REDACTED_FIELD) {
          return <RedactedDateCell />
        }

        return <CollectionDateCell date={date} settlementStatus={row.settlementStatus} />
      },
      // NOTE when there is no second phase, the payment is the collection/first-phase payment too (direct payment)
      getCellValueToExport: (row) => dateToReadableDateTime(row.disbursementPaymentAt || row.collectionPaymentAt),
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.dep-date' }),
      name: 'departureDate',
      // eslint-disable-next-line react/display-name
      getCellValue: (row) => <DepartureDateCell departureDate={row.departureDate} tags={row.tags} />,
      getCellValueToExport: (row) => dateToReadableDate(row.departureDate),
      filter: { type: FILTER_TYPES.DATE },
      width: DEFAULT_COLUMN_WIDTH_VALUES.S,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.due' }),
      name: 'earliestDueDate',
      type: 'date',
      filter: {
        type: FILTER_TYPES.DATE,
        predefinedRanges: [DATE_RANGES.LAST_21_DAYS, DATE_RANGES.THIS_WEEK],
      },
      getCellValueToExport: (row) => dateToReadableDate(row.earliestDueDate),
      width: DEFAULT_COLUMN_WIDTH_VALUES.S,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.original-amount' }),
      name: 'originalAmountInCents',
      // eslint-disable-next-line react/display-name
      getCellValue: (row) => (
        <OriginalAmountCell originalAmountInCents={row.originalAmountInCents} tags={row.tags} currency={row.currency} />
      ),
      getCellValueToExport: (row) => row.originalAmountInCents,
      summary:
        filters?.currency?.length > 1 || !isAllBookingsSameCurrency
          ? null
          : (total, row) =>
              total + parseInt(row?.updatedValues?.originalAmountInCents || row?.originalAmountInCents, 10) ?? 0,
      summaryCurrency: bookingsCurrency,
      filter: { type: FILTER_TYPES.CURRENCY },
      align: 'right',
      type: 'currency',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.currency' }),
      name: 'currency',
      getCellValue: (row) => row?.currency ?? '',
      getCellValueToExport: (row) => row?.currency ?? '',
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: {
            [CURRENCY_CODE_TO_NAME[CURRENCY_CODE.GBP]]: CURRENCY_CODE_TO_NAME[CURRENCY_CODE.GBP],
            [CURRENCY_CODE_TO_NAME[CURRENCY_CODE.EUR]]: CURRENCY_CODE_TO_NAME[CURRENCY_CODE.EUR],
          },
        },
      },
      titleToExport: 'Trans_Currency',
      width: DEFAULT_COLUMN_WIDTH_VALUES.S,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.current-amount' }),
      name: 'currentAmountInCents',
      titleToExport: 'Trans_Current_Amount',
      getCellValue: (row) => <CurrentAmountCell {...row} />,
      getCellValueToExport: (row) => row.currentAmountInCents,
      // eslint-disable-next-line react/display-name
      editComponent: (props) => <CurrentAmountEditField {...props} />,
      disableEditing: columnDisableEditing,
      summary:
        filters?.currency?.length > 1 || !isAllBookingsSameCurrency
          ? null
          : (total, row) =>
              total + parseInt(row?.updatedValues?.currentAmountInCents || row?.currentAmountInCents, 10) ?? 0,
      summaryCurrency: bookingsCurrency,
      filter: { type: FILTER_TYPES.CURRENCY },
      align: 'right',
      type: 'currency',
      width: DEFAULT_COLUMN_WIDTH_VALUES.S,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.invoice-status' }),
      name: 'status',
      titleToExport: 'Trans_Status',
      getCellValue: InvoiceStatusCell,
      // eslint-disable-next-line react/display-name
      editComponent: (props) => <BookingStatusEditField {...props} />,
      disableEditing: columnDisableEditing,
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options:
            getQuickViewRole(quickView) === ACCOUNT_ROLE.SELLER
              ? Object.fromEntries(
                Object.entries(INVOICE_STATUSES_SELLER_TO_NAME)
                  .map(([k, v]) => [k, formatMessage({ id: `invoice-status-seller.${kebabCase(v)}` })])
              )
              : Object.fromEntries(
                Object.entries(INVOICE_STATUSES_BUYER_TO_NAME)
                  .map(([k, v]) => [k, formatMessage({ id: `invoice-status-buyer.${kebabCase(v)}` })])
              ),
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.reason-code' }),
      name: 'reasonCode',
      titleToExport: 'Reason_Code',
      getCellValue: ReasonCodeCell,
      getCellValueToExport: (row) =>
        row.reasonCode ? `${row.reasonCode} - ${REASON_CODE_TO_DESCRIPTION[row.reasonCode]}` : '',
      // eslint-disable-next-line react/display-name
      editComponent: (props) => <ReasonCodeEditField {...props} />,
      disableEditing: columnDisableEditing,
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: {
            ...Object.fromEntries(
              Object.entries(REASON_CODE_TO_DESCRIPTION)
                .map(([k, v]) => [k, formatMessage({ id: `reason-code-to-description.${kebabCase(v)}` })])
            ),
          },
        },
      },
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      title: formatMessage({ id: 'booking-review.buyer-notes' }),
      name: 'buyerNotes',
      titleToExport: 'Buyer_Notes',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.cl-description' }),
      name: 'commercialLinkDisplayName',
      getCellValue: (row) => (
        <CommercialLinkDisplayNameCell displayName={row.commercialLink?.displayName} tags={row.tags} isImport={false} />
      ),
      getCellValueToExport: (row) => row.commercialLinkDisplayName,
      filter: true,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.updated-by' }),
      name: 'updatedBy',
      filter: true,
      width: DEFAULT_COLUMN_WIDTH_VALUES.S,
    },
    {
      ...useGridTitleWithHelpPopup({ id: 'booking-review.updated-at' }),
      name: 'updatedAt',
      getCellValueToExport: (row) => dateToReadableDateTime(row.updatedAt),
      filter: { type: FILTER_TYPES.DATE },
      type: 'datetime',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.tl-transaction-id' }),
      name: 'id',
      titleToExport: 'TL_Trans_ID',
      width: DEFAULT_COLUMN_WIDTH_VALUES.XL,
    },
    {
      title: formatMessage({ id: 'booking-review.transaction-type' }),
      name: 'transType',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      getCellValue: (row) => TransTypeDescription[row.transType],
      getCellValueToExport: (row) => row.transType,
    },
    {
      title: formatMessage({ id: 'booking-review.nett-amount' }),
      name: 'bookingNettAmount',
      getCellValue: (row) => <AmountCell amount={row.bookingNettAmount} currency={row.currency} />,
      getCellValueToExport: (row) => formatAmountForExport(row.bookingNettAmount),
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.comm-amount' }),
      name: 'bookingComAmount',
      getCellValue: (row) => <AmountCell amount={row.bookingComAmount} currency={row.currency} />,
      getCellValueToExport: (row) => formatAmountForExport(row.bookingComAmount),
      width: DEFAULT_COLUMN_WIDTH_VALUES.XL,
    },
    {
      title: formatMessage({ id: 'booking-review.sell-amount' }),
      name: 'bookingSellAmount',
      getCellValue: (row) => <AmountCell amount={row.bookingSellAmount} currency={row.currency} />,
      getCellValueToExport: (row) => formatAmountForExport(row.bookingSellAmount),
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.nett-tax-amount' }),
      name: 'bookingNettTaxAmount',
      getCellValue: (row) => <AmountCell amount={row.bookingNettTaxAmount} currency={row.currency} />,
      getCellValueToExport: (row) => formatAmountForExport(row.bookingNettTaxAmount),
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.comm-tax-amount' }),
      name: 'bookingComTaxAmount',
      getCellValue: (row) => <AmountCell amount={row.bookingComTaxAmount} currency={row.currency} />,
      getCellValueToExport: (row) => formatAmountForExport(row.bookingComTaxAmount),
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.sell-tax-amount' }),
      name: 'bookingSellTaxAmount',
      getCellValue: (row) => <AmountCell amount={row.bookingSellTaxAmount} currency={row.currency} />,
      getCellValueToExport: (row) => formatAmountForExport(row.bookingSellTaxAmount),
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.voucher-number' }),
      name: 'tktVchrNumber',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.conf-number' }),
      name: 'confNumber',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.pax-no' }),
      name: 'transPaxNo',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.product-type' }),
      name: 'transProductType',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.destination' }),
      name: 'destination',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.dest-country' }),
      name: 'destCountry',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.seller-notes' }),
      name: 'sellerNotes',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.seller-invoice-no' }),
      name: 'sellerInvoiceNo',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.buyer-booking-ref' }),
      name: 'buyerBookingRef',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.optional-1' }),
      name: 'optional1',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.optional-2' }),
      name: 'optional2',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
    {
      title: formatMessage({ id: 'booking-review.optional-3' }),
      name: 'optional3',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
    },
  ].filter(Boolean)

  const queryParams = filterValuesToQueryParams({ quickView, ...filters }, currUserCompany)
  // manage cycle dates flag - do not want to fetch invoices multiple times when dates are being fetched
  const [hasDates, setHasDates] = useState(false)
  useEffect(() => {
    setHasDates(!!latestCollectionPayments)
  }, [latestCollectionPayments])

  useEffect(() => {
    if (currUserCompany && currUserCompany.id && filters && quickView && hasDates) {
      fetchInvoices(queryParams)
    }
    // Refetch invoices only when quickView filter is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currUserCompany, filters])

  useEffect(() => {
    if (isQuickViewSelectedPast !== null && !!currUserCompanyId) {
      fetchLatestCycles(isQuickViewSelectedPast)
      setHasDates(false)
    }
  }, [currUserCompanyId, isQuickViewSelectedPast, fetchLatestCycles])

  useEffect(() => {
    return () => clearInvoices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!!currUserCompany && !!currUserCompany.id) {
      if (isProcessingEntity) {
        setQuickView(quickViewStorage[ACCOUNT_ROLE.PROCESSING_ENTITY])
      } else {
        setQuickView(quickViewStorage[ACCOUNT_ROLE.BUYER])
      }
    }
  }, [currUserCompany, quickViewStorage, isProcessingEntity])

  useEffect(() => {
    updateFiltersOnQuickViewChange(filters, setFilters, quickView, latestCollectionPayments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickView, latestCollectionPayments])

  const handleRedirectToAddRemittance = (rowIds) => {
    const firstRowId = rowIds.length ? rowIds[0] : undefined
    if (firstRowId !== undefined) {
      const rowData = invoicesInPage.filter((_row) => rowIds.includes(_row.id))
      selectInvoiceForAddRemittance(rowData)
      redirectTo({ pathname: ROUTE_URL.createRemittanceTransaction })
    }
  }

  const handleRowUpdate = ({ row, change }) => {
    const isAccepted = change.status === STATUSES.ACCEPTED
    const shouldPatch =
      isAccepted ||
      (change.status === STATUSES.AMENDED &&
        Number(change.currentAmountInCents) !== row.originalAmountInCents &&
        !!change.reasonCode) ||
      (change.status === STATUSES.REJECTED && Number(change.currentAmountInCents) === 0 && !!change.reasonCode)

    // We only patch row when the whole row data is valid
    if (shouldPatch) {
      const patchInvoice = {
        id: row.id,
        status: change.status,
        reasonCode: isAccepted ? '' : change.reasonCode,
      }
      if (Reflect.has(change, 'currentAmountInCents')) {
        patchInvoice.amendedAmount = Number(change.currentAmountInCents)
      }
      updateInvoice(patchInvoice)
    }
  }

  const handleUpdateStatus = (rowIds, bulkUpdateRows) => {
    const patchInvoices = []
    const bulkUpdate = []
    let confirmationNeeded = false
    rowIds.forEach((rowId) => {
      const rowData = invoicesInPage.find((invoice) => invoice.id === rowId)
      if (rowData.status === STATUSES.AMENDED || rowData.status === STATUSES.REJECTED) confirmationNeeded = true
      const change = {
        status: STATUSES.ACCEPTED,
        amendedAmount: parseInt(rowData.originalAmountInCents, 10),
        reasonCode: '',
      }
      patchInvoices.push({
        id: rowData.id,
        ...change,
      })
      bulkUpdate.push({
        rowId,
        change: { ...change, currentAmountInCents: change.amendedAmount, lastUpdate: Date.now() },
      })
    })
    if (confirmationNeeded) {
      setConfirmOpen(true)
      setConfirmCallback(() => () => {
        bulkUpdateRows(bulkUpdate)
        updateInvoices(patchInvoices)
      })
      return
    }
    bulkUpdateRows(bulkUpdate)
    updateInvoices(patchInvoices)
  }

  const handleQuickViewChange = (event) => {
    setQuickView(event.target.value)
    setQuickViewStorage({
      ...quickViewStorage,
      [isProcessingEntity ? ACCOUNT_ROLE.PROCESSING_ENTITY : ACCOUNT_ROLE.BUYER]: event.target.value,
    })
  }

  const actions = [
    {
      title: formatMessage({ id: 'bulk-actions.accept-selected' }),
      icon: 'check_circle',
      onClick: handleUpdateStatus,
      validate: (selection) => {
        const selectedRows = invoicesInPage.filter((invoice) => selection.includes(invoice.id))
        const canAcceptInvoices = getQuickViewRole(quickView) === ACCOUNT_ROLE.BUYER
        if (!canAcceptInvoices) {
          throw new Error('Only destination company can accept selected invoices')
        }
        const isCommercialLinkPending = selectedRows.some((row) =>
          row.tags.includes(INVOICE_TAGS.PENDING_COMMERCIAL_LINK),
        )
        if (isCommercialLinkPending) {
          throw new Error('Invoices with pending commercial links can not be accepted')
        }
        if (selectedRows.some((row) => row.isLocked)) {
          throw new Error('Locked invoices can not be accepted')
        }
        return true
      },
    },
  ]

  if (isProcessingEntity) {
    actions.push({
      title: formatMessage({ id: 'bulk-actions.add-remittance' }),
      icon: 'add_circle',
      onClick: handleRedirectToAddRemittance,
      validate: (selection) => {
        const selectedRows = invoicesInPage.filter((invoice) => selection.includes(invoice.id))
        if (selectedRows.find((row) => row?.ir?.length)) {
          throw new Error('The selected invoice already has a remittance')
        }
        if (selectedRows.some((row) => [STATUSES.VOID, STATUSES.REJECTED].includes(row.status))) {
          throw new Error('Rejected or voided invoices cannot have remittance')
        }
        if (selectedRows.some((row) => row.isLocked)) {
          throw new Error('Locked invoices cannot have new remittance')
        }
        const commercialLinks = new Set(selectedRows.map((row) => row?.commercialLink?.id))
        if (Array.from(commercialLinks).length > 1) {
          throw new Error('Cannot add remittance for bookings with different commercial links')
        }
        if (!isProcessingEntity) {
          throw new Error('Only a processing entity can add a remittance')
        }
        return true
      },
    })
  }

  return (
    <AuthView location={location} title="Transactions">
      <BreadcrumbMenuTransactions />
      <Grid container spacing={1}>
        <Grid item xs={12} className={styles.tableBlock}>
          {!hasDates ? <Spinner open={true} /> :
          <TransactionsTable
            // the key ensures the table re-renders on quickView change
            // to hide/show columns depending on it, otherwise the
            // TableColumnResizing plugin crashes the page
            key={String(quickView)}
            lastUpdate={lastUpdate}
            name={`${quickView}_review_bookings`}
            height={700}
            title={
              <>
                <QuickViewsDropdown
                  isProcessingEntity={isProcessingEntity}
                  quickView={quickView}
                  onQuickViewChange={handleQuickViewChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.hideActioned}
                      onChange={() => setFilters((prev) => ({ ...prev, hideActioned: !prev.hideActioned }))}
                      color="primary"
                    />
                  }
                  label={formatMessage({ id: 'booking-review.hide-actioned' })}
                />
              </>
            }
            disableRow={(row) => row.isLocked}
            columns={columns}
            columnChooserGroups={columnChooserGroups}
            propMach={filters}
            editable={{
              disabled: (rowData) => rowData?.ir?.length > 0,
              onRowsUpdate: handleRowUpdate,
            }}
            filtering={{
              defaultFilters: initialFilterValues,
              filters,
              onChange: (newValue) => {
                setFilters(newValue)
              },
              clearFilters: () => {
                setFilters((prev) =>
                  Object.assign(initialFilterValues, {
                    ...(isQuickViewSelectedPast && {
                      collectionPaymentAt: prev.collectionPaymentAt,
                      cycleStartAt: null,
                    }),
                    ...(!isQuickViewSelectedPast && {
                      cycleStartAt: prev.cycleStartAt,
                      collectionPaymentAt: null,
                    }),
                  }),
                )
              },
            }}
            selectable={{
              actions,
            }}
            sort={{
              columnName: isCurrentWeekQuickView(quickView) ? 'cycleStartAt' : 'collectionPaymentAt',
              direction: 'DESC',
            }}
            data={FEATURE_PAGINATION_ENABLED ? parsedInvoices : filteredInvoices}
            onPageChanged={() => {}}
            detailPanel={(rowData) => (
              <InnerTransactionDetailsTable
                disableExport
                rowData={rowData}
                title="Related Remittances"
                detailsType="remittances"
              />
            )}
            {...(!FEATURE_PAGINATION_ENABLED && {
              totalNumberOfItems: totalNumberOfInvoices,
            })}
            {...(FEATURE_PAGINATION_ENABLED && {
              pagination: {
                onPageChanged: (e) => {
                  setFilters((prev) => ({
                    ...prev,
                    currentPage: e.currentPage + 1,
                    itemsPerPage: e.pageSize,
                  }))
                },
                totalNumberOfItems: invoices?.total || 0,
              },
            })}
            extra={<RefreshButton onClick={() => fetchInvoices(queryParams)} />}
            isRowExpandable={(row) => row.ir?.length > 0}
            defaultHiddenColumns={DEFAULT_HIDDEN_COLUMNS[quickView]}
          />
        }
        </Grid>
      </Grid>
      <ConfirmDialog
        title="Accept selected transactions?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirmCallback}
      >
        You are about to accept all selected transactions. All previous statuses will be updated, do you want to
        proceed?
      </ConfirmDialog>
      <SnackbarComponent status={snackbar.isOpened} error={snackbar.isError} text={snackbar.successOrErrorText} />
    </AuthView>
  )
}

BookingsReview.propTypes = {
  redirectTo: PropTypes.func,
  location: PropTypes.object,
  currUserCompanyId: PropTypes.string,
  currUserRole: PropTypes.string,
  currUserCompany: PropTypes.object,
  fetchInvoices: PropTypes.func,
  fetchLatestCycles: PropTypes.func,
  clearInvoices: PropTypes.func,
  invoices: PropTypes.oneOf([PropTypes.array, PropTypes.object]),
  updateInvoice: PropTypes.func,
  updateInvoices: PropTypes.func,
  snackbar: PropTypes.object,
  selectInvoiceForAddRemittance: PropTypes.func,
  latestCycleStartDates: PropTypes.array,
  latestCollectionPayments: PropTypes.array,
  latestCollectionDates: PropTypes.array,
  latestDisbursementDates: PropTypes.array,
  lastUpdate: PropTypes.number,
}

export default BookingsReview
