/* eslint-disable react/display-name */
import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SelectOnTabMenuItem from 'components/SelectOnTabMenuItem'
import Chip from '@material-ui/core/Chip'
import CancelIcon from '@material-ui/icons/Cancel'
import FormHelperText from '@material-ui/core/FormHelperText'

const CountrySelectInput = memo(({ defaultValue, value, valueKey = 'id', countries, fetchCountries, onChange, label = 'Country', multiple = false, onDelete, endAdornment, options, helperText, dense, ...props }) => {
  const [filteredCountries, setFilteredCountries] = React.useState([])
  
  useEffect(() => {
    if(!countries?.length) {
      fetchCountries()
    }
  }, [fetchCountries, countries])
  
  useEffect(() => {
    if (options) {
      setFilteredCountries(countries.filter((item) => options.includes(item[valueKey])))
    } else {
      setFilteredCountries(countries)
    }
  }, [ countries, options, valueKey ])

    const handleChange = (e) => {
      onChange(e.target.value)
    }

    if (!countries || !countries.length) {
      return <></>
    }

  return (
    <FormControl {...props} {...dense && { size: 'small' }}>
      <InputLabel htmlFor="countries">{label}</InputLabel>
      <Select
        multiple={multiple}
        defaultValue={defaultValue}
        value={value || (multiple ? [] : null)}
        onChange={handleChange}
        label={label}
        inputProps={{
          name: label,
          id: 'countries'
        }}
        {...multiple && { renderValue: selected => <div 
          style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}
        >
        {selected && selected.map((_value) => (
          <Chip
            key={_value}
            label={countries.find((item) => item[valueKey] === _value).countryName}
            clickable
            deleteIcon={
              <CancelIcon
                onMouseDown={(e) => e.stopPropagation()}
              />
            }
            onDelete={() => onDelete(_value)}
          />
        ))}
        </div>
        }}
        {...endAdornment && { endAdornment }}
        MenuProps={{variant: "menu"}}
      >
        {filteredCountries.map((item) => (
          <SelectOnTabMenuItem key={item.id} value={item[valueKey]} onChange={handleChange}>{item.countryName}</SelectOnTabMenuItem>
        ))}
      </Select>
      {helperText && helperText !== ' ' && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
})

CountrySelectInput.propTypes = {
  fetchCountries: PropTypes.func,
  countries: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  value: PropTypes.string.isRequired,
  valueKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
}

export default CountrySelectInput
