import { TYPE } from '../../formTypes'
import { corporateDocumentTypeStakeholder } from '../values'

export const contact = {
  contactNo: {
    label: "Contact No",
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
      'string.pattern.base',
    ],
  },
  email: {
    label: "Email",
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
      'string.email',
    ],
  },
  countryCode: {
    label: "Country Code",
    type: TYPE.COUNTRY,
    required: true
  },
}

export const person = {
  firstName: {
    label: "First Name",
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  middleName: {
    label: "Middle Name",
    type: TYPE.STRING,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  lastName: {
    label: "Last Name",
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  nationality: {
    label: "Nationality",
    type: TYPE.COUNTRY,
    required: true
  },
  dateOfBirth: {
    label: "Date Of Birth",
    type: TYPE.DATE,
    past: true,
    required: true
  },
}

export const document = {
  document: {
    label: "Document",
    type: TYPE.FILE
  }
}


export const documentDetailsCorporateStakeholders = {
  documentType: {
    label: 'Document Type',
    type: TYPE.SELECT,
    options: corporateDocumentTypeStakeholder,
    required: true,
  },
  "document.0.document": {
    label: "Document",
    type: TYPE.FILE,
    info: " File size limit: 1.5MB | Accepted formats: PDF, JPG, PNG",
    required: true,
  },
  documentNumber: {
    label: "Document Number",
    type: TYPE.STRING,
    required: true,
    info: 'e.g. incorporation number or passport, etc...'
  },
  documentIssuanceCountry: {
    label: "Document Issuance Country",
    type: TYPE.COUNTRY,
    required: true,
  },
}